import {
  UseTrackEventParams,
  useGenericTrackEvent,
} from '@guider-global/customer-io-hooks';
import { useEvents } from 'hooks';

export function useTrackEvent(
  params: UseTrackEventParams,
  waitForAuthentication = true,
) {
  const { reqEvents, isLoading } = useEvents({ waitForAuthentication });

  const controller = useGenericTrackEvent({
    isLoading: isLoading(),
    reqEvents,
    ...params,
  });

  return controller;
}
