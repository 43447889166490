import {
  GetTokenWithPopupOptions,
  OAuthError,
  useAuth0,
} from '@auth0/auth0-react';
import { getOrigin } from '@guider-global/front-end-utils';
import axios from 'axios';
import { useMixpanelEvents } from 'hooks';

const { REACT_APP_AUTH0_AUDIENCE, REACT_APP_BASE_API_URL } = process.env;

export const base = axios.create({
  baseURL: REACT_APP_BASE_API_URL,
});

export const useApi = () => {
  const { getAccessTokenSilently, getAccessTokenWithPopup, logout } =
    useAuth0();
  const { resetUserIdentity } = useMixpanelEvents();

  const opts: GetTokenWithPopupOptions = {
    authorizationParams: {
      scope: 'openid',
      audience: REACT_APP_AUTH0_AUDIENCE ?? REACT_APP_BASE_API_URL,
    },
  };

  const getAccessToken = async () => {
    try {
      return await getAccessTokenSilently(opts);
    } catch (error) {
      const errorJson: OAuthError = JSON.parse(JSON.stringify(error));
      const errorId = errorJson.error;
      if (errorId === 'consent_required') {
        return getAccessTokenWithPopup(opts);
      } else {
        console.error(error);
        await logout({ logoutParams: { returnTo: getOrigin() } });
        resetUserIdentity();
        throw new Error('Unauthenticated');
      }
    }
  };

  const getApi = async <T>(path: string): Promise<T[] | undefined> => {
    const accessToken = await getAccessToken();

    try {
      const response = await base.get<T[]>(path, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return response.data.data;
    } catch (error) {
      throw new Error('Error getting the data');
    }
  };

  const postApi = async <T>(
    path: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    payload: any,
  ): Promise<T[] | undefined> => {
    const accessToken = await getAccessToken();

    try {
      const response = await base.post<T[]>(path, payload, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return response.data.data;
    } catch (error) {
      throw new Error('Error updating the data');
    }
  };

  const putApi = async <T>(path: string): Promise<T[] | undefined> => {
    const accessToken = await getAccessToken();

    try {
      const response = await base.put<T[]>(path, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return response.data.data;
    } catch (error) {
      throw new Error('Error updating the data');
    }
  };

  const patchApi = async <T>(
    path: string,
    payload: T,
  ): Promise<T[] | undefined> => {
    const accessToken = await getAccessToken();

    try {
      const response = await base.patch<T[]>(path, payload, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return response.data.data;
    } catch (error) {
      throw new Error('Error updating the data');
    }
  };

  const deleteApi = async <T>(path: string): Promise<T[] | undefined> => {
    const accessToken = await getAccessToken();

    try {
      const response = await base.delete(path, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return response.data.data;
    } catch (error) {
      throw new Error('Error deleting the data');
    }
  };

  return { getApi, postApi, putApi, patchApi, deleteApi };
};
