import { IChatParticipant } from '@guider-global/shared-types';
import { IUseGuiderSWRParams, useGuiderSWR } from '@guider-global/swr';

export function useChatParticipants({
  waitForAuthentication = true,
  options,
}: Omit<IUseGuiderSWRParams<IChatParticipant>, 'url'>) {
  const { data, request, revalidate, error, isLoading, isValidating } =
    useGuiderSWR<IChatParticipant>({
      waitForAuthentication,
      url: '/chatParticipants',
      options: {
        keepPreviousData: true,
        ...options,
      },
    });

  return {
    chatParticipants: data,
    reqChatParticipants: request,
    revalidateChatParticipants: revalidate,
    errorChatParticipants: error,
    isLoadingChatParticipants: isLoading,
    isValidatingChatParticipants: isValidating,
    isErrorChatParticipants: Boolean(error),
  };
}
