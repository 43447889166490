import { getISOStringWithoutMilliseconds } from '@guider-global/front-end-utils';
import mixpanel from 'mixpanel-browser';
import { useCallback, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

type MixpanelEventCustomProps = Record<string, any>;

interface UseMixpanelEventsProps {
  navigationEventName?: string;
  eventName?: string;
  properties?: MixpanelEventCustomProps;
}

export function useMixpanelEvents(props = {} as UseMixpanelEventsProps) {
  const { navigationEventName, eventName } = props;
  const startTime = Date.now();
  const location = useLocation();

  const trackMixpanelEvent = useCallback(
    (eventName: string, properties?: MixpanelEventCustomProps) => {
      mixpanel.track(eventName, {
        ...properties,
        page_name: location.pathname,
        referrer: document.referrer,
      });

      console.log(`Event ${eventName} tracked on ${location.pathname}`);
    },
    [location],
  );

  const getClickProp = useCallback(
    () => ({ Click: getISOStringWithoutMilliseconds(Date.now()) }),
    [],
  );

  const getScreenTimeProp = useCallback(() => {
    const screenTimeSeconds = (Date.now() - startTime) / 1000;
    return { 'Time spent on screen': screenTimeSeconds };
  }, [startTime]);

  const getScreenTimeAndClickProps = useCallback(
    () => ({ ...getClickProp(), ...getScreenTimeProp() }),
    [getClickProp, getScreenTimeProp],
  );

  const trackClick = useCallback(
    (eventName: string, properties?: MixpanelEventCustomProps) => {
      trackMixpanelEvent(eventName, {
        ...getClickProp(),
        ...properties,
      });
    },
    [trackMixpanelEvent, getClickProp],
  );

  const trackScreenTime = useCallback(
    (eventName: string, properties?: MixpanelEventCustomProps) => {
      trackMixpanelEvent(eventName, {
        ...getScreenTimeProp(),
        ...properties,
      });
    },
    [trackMixpanelEvent, getScreenTimeProp],
  );

  const trackScreenTimeAndClick = useCallback(
    (eventName: string, properties?: MixpanelEventCustomProps) => {
      trackScreenTime(eventName, {
        ...getScreenTimeAndClickProps(),
        ...properties,
      });
    },
    [trackScreenTime, getScreenTimeAndClickProps],
  );

  const setUserProperties = (props: Record<string, any>): void => {
    mixpanel.people.set(props);
  };

  const setOrganizationGroup = (organizationSlug: string): void => {
    mixpanel.set_group('Organization ID', organizationSlug);
  };

  useEffect(() => {
    if (eventName) {
      trackMixpanelEvent(eventName);
    }
  }, [eventName, trackMixpanelEvent]);

  useEffect(() => {
    if (navigationEventName) {
      trackMixpanelEvent(`Navigation - ${navigationEventName}`);
    }
  }, [navigationEventName, trackMixpanelEvent]);

  return {
    startTimeISOString: getISOStringWithoutMilliseconds(startTime),
    trackMixpanelEvent,
    trackClick,
    trackScreenTime,
    trackScreenTimeAndClick,
    setUserProperties,
    setOrganizationGroup,
    resetUserIdentity: mixpanel.reset,
    unionUserProperty: mixpanel.people.union,
    incrementUserPropetry: mixpanel.people.increment,
  };
}
