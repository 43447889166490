import { TSurvey } from '@guider-global/shared-types';
import {
  IPaginationParams,
  IUseGuiderSWRParams,
  useGuiderSWR,
} from '@guider-global/swr';

interface CreateSurveysData
  extends Omit<TSurvey, 'id' | 'user' | 'surveyFields'> {}

interface IUseSurveysRequestParams extends IPaginationParams {}
interface IUseSurveysParams
  extends Omit<
    IUseGuiderSWRParams<TSurvey, Partial<TSurvey>, IUseSurveysRequestParams>,
    'url'
  > {}
export function useSurveys({
  waitForAuthentication = true,
  params,

  options,
}: IUseSurveysParams) {
  const {
    data,
    request,
    revalidate,
    error,
    isLoading,
    isValidating,
    isMutating,
  } = useGuiderSWR<TSurvey, Partial<TSurvey>, IUseSurveysRequestParams>({
    waitForAuthentication,
    options: {
      keepPreviousData: true,
      ...options,
    },
    url: `/surveys`,
    params: {
      ...params,
    },
  });
  const createSurveys = (data: CreateSurveysData) =>
    request({
      method: 'POST',
      url: '/surveys',
      data,
    });
  return {
    surveys: data,
    reqSurveys: request,
    createSurveys,
    revalidateSurveys: revalidate,
    errorSurveys: error,
    isLoadingSurveys: isLoading,
    isValidatingSurveys: isValidating,
    isErrorSurveys: Boolean(error),
    isMutatingSurveys: isMutating,
  };
}
