import { useAuth } from '@guider-global/auth-hooks';
import { useDatadogContext } from '@guider-global/datadog';
import axios from 'axios';
import { useCallback, useEffect, useState } from 'react';

const { REACT_APP_BASE_API_URL } = process.env;

export function useUserPictures(waitForAuthentication = true) {
  const [picture, setPicture] = useState<Blob | undefined>(undefined);
  const [error, setError] = useState<any>(undefined);
  const [loading, setLoading] = useState<boolean>(true);

  // Auth
  const { accessToken } = useAuth({
    waitForAuthentication,
  });
  const { sessionId } = useDatadogContext();

  const getPicture = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await axios.get<Blob>(
        `${REACT_APP_BASE_API_URL}/userPictures`,
        {
          headers: {
            'Content-Type': 'image/jpg',
            Authorization: `Bearer ${accessToken}`,
          },
          responseType: 'blob',
          params: { traceId: sessionId },
        },
      );
      if (data.size === 0) return;
      setPicture(data);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  }, [accessToken, sessionId]);

  useEffect(() => {
    if (!accessToken) return;

    getPicture();
  }, [accessToken, getPicture]);

  return { picture, loading, error };
}
