import { CommunicationAccessToken } from '@azure/communication-identity';
import { useAuth } from '@guider-global/auth-hooks';
import { useAxios } from '@guider-global/redux-axios-hooks';
import { IError } from '@guider-global/shared-types';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { IUseChatTokenResult } from './types';
import { datadogLogs, useDatadogContext } from '@guider-global/datadog';

export function useChatToken(
  profileId?: string | false,
  waitForAuthentication = true,
): IUseChatTokenResult {
  const { accessToken, getAccessToken } = useAuth({
    waitForAuthentication,
  });
  const { sessionId } = useDatadogContext();
  const [loading, setLoading] = useState<boolean>();
  const [token, setToken] = useState<string | undefined>();
  const [error, setError] = useState<IError | undefined>();

  const { requestCallback } = useAxios({
    waitForAuthentication,
    accessToken,
    onExpiredAccessToken: getAccessToken,
  });

  useEffect(() => {
    async function getChatToken() {
      setLoading(true);
      try {
        const axiosResult = await requestCallback<CommunicationAccessToken>({
          url: '/chat/token',
          params: { profileId, traceId: sessionId },
          method: 'POST',
        });
        const { data: axiosData } = axiosResult;
        const { data, status, message, errors, code } = axiosData;
        if (status !== 'success' || data?.length <= 0) {
          console.log('Error getting chat token!', { code, message, errors });
          const errorMessage =
            errors && errors.length > 0
              ? errors.flatMap((error) => error.message).join(',')
              : message;
          const chatError = new Error(errorMessage);
          chatError.name = `${code}`;
          throw chatError;
        }
        setToken(data[0].token);
        setLoading(false);
      } catch (err) {
        const unknownErrorMessage =
          'Unknown error occurred getting users chat token';
        const unknownErrorCode = 'UNKNOWN_ERROR';
        if (axios.isAxiosError(err) || err instanceof Error) {
          console.error({ err });
          setError({
            message: err?.message ?? unknownErrorMessage,
            code: err?.name ?? unknownErrorCode,
          });
          datadogLogs.logger.error('useChatToken:', {
            ...err,
          });
        } else {
          datadogLogs.logger.error('useChatToken:', {
            message: unknownErrorMessage,
            err,
          });
          setError({
            code: 'UNKNOWN_ERROR',
            message: unknownErrorMessage,
          });
        }
        setLoading(false);
      }
    }
    if (accessToken && !token && !loading && !error && profileId) {
      getChatToken();
    }
  }, [
    accessToken,
    token,
    loading,
    error,
    profileId,
    requestCallback,
    sessionId,
  ]);

  return {
    error,
    token,
  };
}
