import { useProfiles, useSettings } from '@guider-global/front-end-hooks';
import { setDocAttrsAndMetaTag } from '@guider-global/front-end-utils';
import { useSanityLocalization } from '@guider-global/sanity-hooks';
import { SanityBaseLanguage } from '@guider-global/shared-types';
import { SessionStorageKeys } from 'hooks';
import { useCallback } from 'react';
import { RootState } from 'store';

interface UseLocalizationResults {
  localeCode?: string;
  handleLanguageChange: (localeCode: string) => Promise<void>;
}

export const useLocalization = (
  organizationSlug: string,
): UseLocalizationResults => {
  // Profile
  const { profile } = useProfiles({});

  // Setting
  const {
    isLoadingSettings,
    settings: getSettings,
    updateSettings,
    createSettings,
  } = useSettings({});

  const setting = getSettings?.at(0);

  const lastUsedLanguage = setting?.localeCode;

  const createOrUpdateSettings = useCallback(
    (localeCode: string) => {
      if (setting) {
        return updateSettings(setting.id, { localeCode });
      } else if (profile) {
        return createSettings({ profile: profile.id, localeCode });
      }
    },
    [createSettings, profile, setting, updateSettings],
  );

  const onLanguageChange = useCallback(
    async (
      localeCode: string,
      textDirection?: SanityBaseLanguage['text_direction'],
    ) => {
      await createOrUpdateSettings(localeCode);
      setDocAttrsAndMetaTag({ localeCode, textDirection });
    },
    [createOrUpdateSettings],
  );

  const { localeCode, handleLanguageChange } = useSanityLocalization<RootState>(
    organizationSlug,
    SessionStorageKeys,
    onLanguageChange,
    isLoadingSettings,
    lastUsedLanguage,
  );

  return {
    localeCode,
    handleLanguageChange,
  };
};
