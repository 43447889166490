import { INotification } from '@guider-global/shared-types';
import {
  IPaginationParams,
  IUseGuiderSWRParams,
  useGuiderSWR,
} from '@guider-global/swr';

interface IUseNotificationsRequestParams extends IPaginationParams {
  latest?: boolean;
}

interface IUseNotificationsParams
  extends Omit<
    IUseGuiderSWRParams<
      INotification,
      Partial<INotification>,
      IUseNotificationsRequestParams
    >,
    'url'
  > {}

export function useNotifications({
  waitForAuthentication = true,
  options,
  params,
}: IUseNotificationsParams) {
  const { data, request, revalidate, error, isLoading, isValidating } =
    useGuiderSWR<
      INotification,
      Partial<INotification>,
      IUseNotificationsRequestParams
    >({
      waitForAuthentication,
      url: '/notifications',
      options: {
        keepPreviousData: true,
        ...options,
      },
      params: { ...params },
    });

  return {
    notifications: data,
    reqNotifications: request,
    notificationsRevalidate: revalidate,
    errorsNotifications: error,
    isLoadingNotifications: isLoading,
    isErrorNotifications: Boolean(error),
    isValidatingNotifications: isValidating,
  };
}
