/* eslint-disable camelcase */
import { useEffect, useState } from 'react';
import axios, { AxiosResponse } from 'axios';
import { getSubDomain } from '@guider-global/front-end-utils';
import { useLocalization } from 'hooks';

const { REACT_APP_BASE_API_URL, REACT_APP_AUTH0_CLIENT_ID } = process.env;

export interface InviteResponse {
  status: string;
  code: number;
  data: { invitationUrl: string };
  message: string;
}
export interface HandleGetInviteParams {
  organizationId: string;
  organizationName: string;
  email: string;
}

export interface UseRegisterInviteResults {
  error?: string;
  inviteUrl?: string;
  loading: boolean;
  handleGetInvite: (params: HandleGetInviteParams) => void;
}

export const useRegisterInvite = (): UseRegisterInviteResults => {
  const organizationSlug = getSubDomain();
  const { localeCode } = useLocalization(organizationSlug);

  // Local State
  const [error, setError] = useState<string>();
  const [inviteUrl, setInviteUrl] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);
  const [getInvite, setGetInvite] = useState<HandleGetInviteParams>();

  const handleGetInvite = ({
    organizationId,
    organizationName,
    email,
  }: HandleGetInviteParams) =>
    setGetInvite({ organizationId, organizationName, email });

  useEffect(() => {
    if (!getInvite) return;
    const createInvite = async ({
      organizationId,
      organizationName,
      email,
    }: HandleGetInviteParams) => {
      setLoading(true);
      try {
        const request: AxiosResponse<InviteResponse | undefined> =
          await axios.request({
            method: 'POST',
            url: `${REACT_APP_BASE_API_URL}/register/invite?id=${organizationId}`,
            data: {
              inviter: {
                name: organizationName,
              },
              invitee: {
                email,
              },
              client_id: REACT_APP_AUTH0_CLIENT_ID,
              user_metadata: {
                localeCode,
              },
            },
          });
        if (request.status === 204) {
          return;
        }

        if (!request.data) {
          setError('no data returned');
          return;
        }
        const inviteUrl = request.data.data.invitationUrl;
        if (!inviteUrl) {
          setError('no invitation returned');
          return;
        }
        setInviteUrl(inviteUrl);
      } catch (err: unknown) {
        setError(`Error getting invite: ${err}`);
      } finally {
        setLoading(false);
      }
    };

    createInvite(getInvite);
    setGetInvite(undefined);
  }, [getInvite, localeCode]);

  return {
    error,
    inviteUrl,
    loading,
    handleGetInvite,
  };
};
