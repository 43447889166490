import {
  DayOfWeekAvailabilities,
  DaysOfWeek,
  IAvailability,
} from '@guider-global/shared-types';

import { getSubDomain } from '@guider-global/front-end-utils';
import { useOrganization } from '@guider-global/sanity-hooks';
import {
  IPaginationParams,
  IUseGuiderSWRParams,
  useGuiderSWR,
} from '@guider-global/swr';
import { useMemo } from 'react';

const DEFAULT_START_TIME = '09:00';
const DEFAULT_END_TIME = '18:00';

interface IUseAvailabilitiesORequestParams extends IPaginationParams {}
interface UseAvailabilitiesOptions
  extends Omit<
    IUseGuiderSWRParams<
      IAvailability,
      Partial<IAvailability>,
      IUseAvailabilitiesORequestParams
    >,
    'url'
  > {
  query?: string;
}

export function useAvailabilities({
  waitForAuthentication = true,
  options,
  params,
  query,
}: UseAvailabilitiesOptions) {
  const { data, request, revalidate, error, isLoading, isValidating } =
    useGuiderSWR<IAvailability>({
      waitForAuthentication,
      url: `/availabilities${query ? query : ''}`,
      options: {
        keepPreviousData: true,
        ...options,
      },
      params: { ...params },
    });

  const organizationSlug = getSubDomain();

  const { organization } = useOrganization({
    organizationSlug: organizationSlug,
    localeCode: 'en_GB',
  });

  const orgStartTime = organization?.availabilities?.start_time;
  const orgEndTime = organization?.availabilities?.end_time;

  const defaultAvailability = useMemo(
    () =>
      DaysOfWeek.reduce((acc, day) => {
        const isNotWeekend = day !== 'saturday' && day !== 'sunday';
        const startTime = orgStartTime ?? DEFAULT_START_TIME;
        const endTime = orgEndTime ?? DEFAULT_END_TIME;

        acc[`${day}Enabled`] = isNotWeekend;
        acc[`${day}Start`] = isNotWeekend ? startTime : '';
        acc[`${day}End`] = isNotWeekend ? endTime : '';

        return acc;
      }, {} as DayOfWeekAvailabilities),
    [orgStartTime, orgEndTime],
  );
  const createAvailabilities = (data: Record<string, any>) => {
    if (!data) {
      console.error('Missing data for createAvailabilities');
      return;
    }

    return request({
      method: 'POST',
      url: `/availabilities`,
      data,
    });
  };

  const updateAvailabilities = (
    availabilityId: string,
    data: Record<string, any>,
  ) => {
    if (!availabilityId || !data) {
      console.error('Missing availabilityId or data for updateAvailabilities');
      return;
    }

    return request({
      method: 'PATCH',
      url: `/availabilities/${availabilityId}`,
      data,
    });
  };

  return {
    defaultAvailability,
    createAvailabilities,
    updateAvailabilities,
    availabilities: data,
    reqAvailabilities: request,
    availabilitiesRevalidate: revalidate,
    errorsAvailabilities: error,
    isLoadingAvailabilities: isLoading,
    isErrorAvailabilities: Boolean(error),
    isValidatingAvailabilities: isValidating,
  };
}
