import { INote } from '@guider-global/shared-types';
import {
  IPaginationParams,
  IUseGuiderSWRParams,
  useGuiderSWR,
} from '@guider-global/swr';

interface UseNotesRequestParams extends IPaginationParams {
  id?: string;
  relationshipId?: string;
}

type UseNotesSWRParams = IUseGuiderSWRParams<
  INote,
  Partial<INote>,
  UseNotesRequestParams
>;
interface UseNotesParams extends Omit<UseNotesSWRParams, 'url'> {}

export function useNotes({
  waitForAuthentication = true,
  ...params
}: UseNotesParams) {
  const {
    data,
    request,
    revalidate,
    error,
    isLoading,
    isValidating,
    isMutating,
  } = useGuiderSWR<INote, Partial<INote>, UseNotesRequestParams>({
    waitForAuthentication,
    url: '/notes',
    ...params,
  });
  return {
    notes: data,
    reqNotes: request,
    revalidateNotes: revalidate,
    errorNotes: error,
    isLoadingNotes: isLoading,
    isValidatingNotes: isValidating,
    isMutatingNotes: isMutating,
    isErrorNotes: Boolean(error),
  };
}
