import { getSubDomain } from '@guider-global/front-end-utils';
import {
  ILeaderboardPosition,
  ILeaderboardPositionQuery,
} from '@guider-global/shared-types';
import {
  IPaginationParams,
  IUseGuiderSWRParams,
  useGuiderSWR,
} from '@guider-global/swr';

interface IUseLeaderboardPositionsRequestParams
  extends IPaginationParams,
    Omit<ILeaderboardPositionQuery, 'id' | 'organizationSlug'> {
  profileId?: string;
}
interface UseLeaderboardPositionsParams
  extends Omit<
    IUseGuiderSWRParams<
      ILeaderboardPosition,
      Partial<ILeaderboardPosition>,
      IUseLeaderboardPositionsRequestParams
    >,
    'url'
  > {}

/**
 * @returns returns leaderboard positions
 */
export function useLeaderboardPositions({
  waitForAuthentication = true,
  params,
  options,
}: UseLeaderboardPositionsParams) {
  const organizationSlug = getSubDomain();
  const { profileId, ...rest } = params ?? {};
  const { data, meta, request, revalidate, error, isLoading, isValidating } =
    useGuiderSWR<ILeaderboardPosition>({
      waitForAuthentication,
      url: profileId
        ? `/leaderboardPositions/${profileId}`
        : `/leaderboardPositions`,
      params: {
        ...{ organizationSlug },
        ...rest,
      },
      options: {
        keepPreviousData: true,
        ...options,
      },
    });

  return {
    leaderboardPositions: data,
    leaderboardMeta: meta,
    reqLeaderboardPositions: request,
    LeaderboardPositionsRevalidate: revalidate,
    errorsLeaderboardPositions: error,
    isLoadingLeaderboardPositions: isLoading,
    isErrorLeaderboardPositions: Boolean(error),
    isValidatingLeaderboardPositions: isValidating,
  };
}
