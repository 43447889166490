import { IVideoParticipant } from '@guider-global/shared-types';
import {
  IPaginationParams,
  IUseGuiderSWRParams,
  useGuiderSWR,
} from '@guider-global/swr';

interface IUseVideoParticipantRequestParams
  extends IPaginationParams,
    Partial<IVideoParticipant> {}

export interface UseVideoParticipantOptions
  extends Omit<
    IUseGuiderSWRParams<
      IVideoParticipant,
      Partial<IVideoParticipant>,
      IUseVideoParticipantRequestParams
    >,
    'url'
  > {
  url?: string;
}

export function useVideoParticipants({
  waitForAuthentication = true,
  ...rest
}: UseVideoParticipantOptions) {
  const { data, request, revalidate, error, isLoading, isValidating } =
    useGuiderSWR<
      IVideoParticipant,
      Partial<IVideoParticipant>,
      IUseVideoParticipantRequestParams
    >({
      waitForAuthentication,
      url: '/videoParticipants',
      ...rest,
    });

  return {
    videoParticipants: data,
    reqVideoParticipants: request,
    revalidateVideoParticipants: revalidate,
    errorVideoParticipants: error,
    isLoadingVideoParticipants: isLoading,
    isValidatingVideoParticipants: isValidating,
    isErrorVideoParticipants: Boolean(error),
  };
}
