import { IMatchV2 } from '@guider-global/shared-types';
import {
  IPaginationParams,
  IUseGuiderSWRParams,
  useGuiderSWR,
} from '@guider-global/swr';

interface IUseMatchesV2RequestParams extends IPaginationParams {}
interface IUseMatchesV2Params
  extends Omit<
    IUseGuiderSWRParams<
      IMatchV2,
      Partial<IMatchV2>,
      IUseMatchesV2RequestParams
    >,
    'url'
  > {
  query?: string;
}
export function useMatchesV2({
  waitForAuthentication = true,
  params,
  query,
  options,
}: IUseMatchesV2Params) {
  const { data, request, revalidate, error, isLoading, isValidating } =
    useGuiderSWR<IMatchV2, Partial<IMatchV2>, IUseMatchesV2RequestParams>({
      waitForAuthentication,
      options: {
        keepPreviousData: true,
        ...options,
      },
      url: `/matchesV2${query}`,
      params: {
        ...params,
      },
    });

  return {
    matchesV2: data,
    reqMatchesV2: request,
    revalidateMatchesV2: revalidate,
    errorMatchesV2: error,
    isLoadingMatchesV2: isLoading,
    isValidatingMatchesV2: isValidating,
    isErrorMatchesV2: Boolean(error),
  };
}
