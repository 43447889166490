import { TOTPSurvey } from '@guider-global/shared-types';
import {
  IPaginationParams,
  IUseGuiderSWRParams,
  useGuiderSWR,
} from '@guider-global/swr';

interface IUseSurveyRequestParams extends IPaginationParams {
  userId?: string;
}

interface UseOTPSurveysOptions
  extends Omit<
    IUseGuiderSWRParams<
      TOTPSurvey,
      Partial<TOTPSurvey>,
      IUseSurveyRequestParams
    >,
    'url'
  > {
  id?: string;
}
export function useOTPSurveys({ options, params, id }: UseOTPSurveysOptions) {
  const { data, request, revalidate, error, isLoading, isValidating } =
    useGuiderSWR<TOTPSurvey>({
      url: `/otp/surveys/${id}`,
      options,
      params,
    });

  return {
    otpSurveys: data,
    reqOTPSurveys: request,
    otpSurveysRevalidate: revalidate,
    errorsOTPSurveys: error,
    isLoadingOTPSurveys: isLoading,
    isErrorOTPSurveys: Boolean(error),
    isValidatingOTPSurveys: isValidating,
  };
}
