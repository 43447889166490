import { usePicture } from '@guider-global/azure-storage-hooks';
import { useProfiles, useUsers } from '@guider-global/front-end-hooks';

export const useProfilePicture = () => {
  // Users
  const { user } = useUsers({});
  const oauthPicture = user ? user.picture : undefined;

  const { loading, getImage } = usePicture({});

  //  Profiles
  const { profile, isLoadingProfiles } = useProfiles({});
  const profilePicture = profile?.picture;
  const firstLetter = profile?.firstName.charAt(0).toUpperCase();
  const lastLetter = profile?.lastName.charAt(0).toUpperCase();

  const isLoading = !user || !profile || isLoadingProfiles || loading;

  const picture = !profilePicture ? oauthPicture : getImage(profilePicture);

  const initials = `${firstLetter}${lastLetter}`;

  return {
    initials,
    loading: isLoading,
    picture,
  };
};
