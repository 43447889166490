import { useEffect, useState } from 'react';
import { useAuth } from '@guider-global/auth-hooks';
import { useAxios } from '@guider-global/redux-axios-hooks';
import { datadogLogs, useDatadogContext } from '@guider-global/datadog';

type Unavailabilities = Record<string, string[]>;
interface UnavailabilitiesResult {
  data: Unavailabilities;
}

interface UseUnavailabilitiesProps {
  organizationSlug: string | undefined;
  userId: string | undefined;
  date: string | undefined;
  timezone: string | undefined;
}

export function useUnavailabilities({
  organizationSlug,
  userId,
  date,
  timezone,
}: UseUnavailabilitiesProps) {
  const [isLoadingUnavailabilities, setIsLoadingUnavailabilities] =
    useState(false);
  const [unavailabilities, setUnavailabilities] = useState<Unavailabilities>(
    {},
  );

  const { accessToken, getAccessToken } = useAuth({});
  const { requestCallback } = useAxios({
    waitForAuthentication: true,
    accessToken,
    onExpiredAccessToken: getAccessToken,
  });
  const { sessionId } = useDatadogContext();

  useEffect(() => {
    if (!organizationSlug || !userId || !date || !timezone) return;

    const reqUnavailabilities = async () => {
      try {
        setIsLoadingUnavailabilities(true);
        const axiosResult = await requestCallback({
          url: '/unavailabilities',
          params: {
            organizationSlug: organizationSlug,
            userId: userId,
            date: date,
            timeZone: timezone,
            traceId: sessionId,
          },
        });
        const { data: unavailabilities } =
          axiosResult.data as unknown as UnavailabilitiesResult;
        setUnavailabilities(unavailabilities);
      } catch (error) {
        datadogLogs.logger.error('useUnavailabilities:reqUnavailabilities', {
          error,
        });
        console.error(error);
      } finally {
        setIsLoadingUnavailabilities(false);
      }
    };

    reqUnavailabilities();
  }, [organizationSlug, userId, date, timezone, requestCallback, sessionId]);

  return {
    isLoadingUnavailabilities,
    unavailabilities,
  };
}
