import { UserIntegration } from '@guider-global/shared-types';
import { IUseGuiderSWRParams, useGuiderSWR } from '@guider-global/swr';

export type UseUserIntegrationsOptions = Omit<
  IUseGuiderSWRParams<UserIntegration>,
  'url'
>;

export function useUserIntegrations({
  waitForAuthentication = true,
  options,
}: UseUserIntegrationsOptions) {
  const { data, request, revalidate, error, isLoading, isValidating } =
    useGuiderSWR<UserIntegration>({
      waitForAuthentication,
      url: '/userIntegrations',
      options: {
        keepPreviousData: true,
        ...options,
      },
    });

  return {
    userIntegrations: data,
    reqUserIntegrations: request,
    userIntegrationsRevalidate: revalidate,
    errorsUserIntegrations: error,
    isLoadingUserIntegrations: isLoading,
    isErrorUserIntegrations: Boolean(error),
    isValidatingUserIntegrations: isValidating,
  };
}
