import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import { App, ErrorComponent } from 'components';
import { store } from './store';
import { Provider } from 'react-redux';
import { ErrorBoundary } from '@guider-global/ui';
import { datadogLogs } from '@guider-global/datadog';
import { MaintenancePage } from 'pages';

const {
  REACT_APP_DATADOG_CLIENT_TOKEN,
  REACT_APP_DATADOG_SITE,
  REACT_APP_DATADOG_LOGGING_ENABLED,
  REACT_APP_DOWN_FOR_MAINTENANCE,
} = process.env;

function logToDatadog(error: unknown, errorInfo: unknown) {
  if (
    REACT_APP_DATADOG_CLIENT_TOKEN &&
    REACT_APP_DATADOG_SITE &&
    REACT_APP_DATADOG_LOGGING_ENABLED
  ) {
    datadogLogs.logger.error('Error Boundary thrown', {
      error,
      errorInfo,
    });
  }
}

const isDownForMaintenance = REACT_APP_DOWN_FOR_MAINTENANCE === 'true';

const container = document.getElementById('root');

const root = createRoot(container!);

if (isDownForMaintenance) {
  root.render(
    <React.StrictMode>
      <MaintenancePage />
    </React.StrictMode>,
  );
} else {
  root.render(
    <React.StrictMode>
      <Provider store={store}>
        <ErrorBoundary
          logger={logToDatadog}
          fallback={(code, message) => ErrorComponent({ message, code })}
        >
          <App />
        </ErrorBoundary>
      </Provider>
    </React.StrictMode>,
  );
}
