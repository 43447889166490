import { IMatch } from '@guider-global/shared-types';
import {
  IPaginationParams,
  IUseGuiderSWRParams,
  useGuiderSWR,
} from '@guider-global/swr';

interface IUseMatchesRequestParams extends IPaginationParams {}
interface IUseMatchesParams
  extends Omit<
    IUseGuiderSWRParams<IMatch, Partial<IMatch>, IUseMatchesRequestParams>,
    'url'
  > {
  query: string;
}
export function useMatches({
  waitForAuthentication = true,
  params,
  query,
  options,
}: IUseMatchesParams) {
  const { data, request, revalidate, error, isLoading, isValidating } =
    useGuiderSWR<IMatch, Partial<IMatch>, IUseMatchesRequestParams>({
      waitForAuthentication,
      options: {
        keepPreviousData: true,
        ...options,
      },
      url: `/matches${query}`,
      params: {
        ...params,
      },
    });

  return {
    matches: data,
    reqMatches: request,
    revalidateMatches: revalidate,
    errorMatches: error,
    isLoadingMatches: isLoading,
    isValidatingMatches: isValidating,
    isErrorMatches: Boolean(error),
  };
}
