import { IReview } from '@guider-global/shared-types';
import {
  IPaginationParams,
  IUseGuiderSWRParams,
  useGuiderSWR,
} from '@guider-global/swr';

interface IUseReviewsRequestParams extends IPaginationParams {}

interface IUseReviewsParams
  extends Omit<
    IUseGuiderSWRParams<IReview, Partial<IReview>, IUseReviewsRequestParams>,
    'url'
  > {}

export function useReviews({
  waitForAuthentication = true,
  options,
  params,
}: IUseReviewsParams) {
  const { data, request, revalidate, error, isLoading, isValidating } =
    useGuiderSWR<IReview, Partial<IReview>, IUseReviewsRequestParams>({
      waitForAuthentication,
      url: '/reviews',
      options: {
        keepPreviousData: true,
        ...options,
      },
      params: { ...params },
    });

  return {
    reviews: data,
    reqReviews: request,
    reviewsRevalidate: revalidate,
    errorsReviews: error,
    isLoadingReviews: isLoading,
    isErrorReviews: Boolean(error),
    isValidatingReviews: isValidating,
  };
}
