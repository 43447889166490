import { useSessionStorageResults } from '@guider-global/front-end-utils';
import { useSessionStorage } from '@guider-global/front-end-utils';
import { SharedSessionStorageKeys } from '@guider-global/shared-types';

export enum FrontEndSessionStorageKeys {
  REGISTER_REDIRECT = 'post-register-redirect',
}

export const SessionStorageKeys = {
  ...FrontEndSessionStorageKeys,
  ...SharedSessionStorageKeys,
} as const;

export function useSessionStorageTyped<P>(
  key: (typeof SessionStorageKeys)[keyof typeof SessionStorageKeys],
  defaultValue?: P,
): useSessionStorageResults<P> {
  return useSessionStorage<P, typeof SessionStorageKeys>(key, defaultValue);
}
