import { ISession } from '@guider-global/shared-types';
import { IUseGuiderSWRParams, useGuiderSWR } from '@guider-global/swr';

interface UseSessionParams extends Omit<IUseGuiderSWRParams<ISession>, 'url'> {
  url?: string;
}

export function useSessions({
  waitForAuthentication = true,
  ...rest
}: UseSessionParams) {
  const {
    data,
    request,
    revalidate,
    meta,
    error,
    isLoading,
    isValidating,
    isMutating,
  } = useGuiderSWR<ISession>({
    waitForAuthentication,
    url: '/sessions',
    ...rest,
  });

  return {
    sessions: data,
    sessionsMeta: meta,
    reqSessions: request,
    sessionsRevalidate: revalidate,
    errorsSessions: error,
    isLoadingSessions: isLoading,
    isErrorSessions: Boolean(error),
    isValidatingSessions: isValidating,
    isMutatingSessions: isMutating,
  };
}
