import { ITokenVideo } from '@guider-global/shared-types';
import {
  IPaginationParams,
  IUseGuiderSWRParams,
  useGuiderSWR,
} from '@guider-global/swr';

interface IUseVideoTokenRequestParams extends IPaginationParams {}
interface IUseVideoTokenParams
  extends Omit<
    IUseGuiderSWRParams<
      ITokenVideo,
      Partial<ITokenVideo>,
      IUseVideoTokenRequestParams
    >,
    'url'
  > {}
export function useVideoTokens({
  waitForAuthentication = true,
  params,

  options,
}: IUseVideoTokenParams) {
  const { data, request, revalidate, error, isLoading, isValidating } =
    useGuiderSWR<
      ITokenVideo,
      Partial<ITokenVideo>,
      IUseVideoTokenRequestParams
    >({
      waitForAuthentication,
      options: {
        keepPreviousData: true,
        ...options,
      },
      url: `/video/token`,
      params: {
        ...params,
      },
    });

  return {
    videoTokens: data,
    reqVideoTokens: request,
    revalidateVideoTokens: revalidate,
    errorVideoTokens: error,
    isLoadingVideoTokens: isLoading,
    isValidatingVideoTokens: isValidating,
    isErrorVideoTokens: Boolean(error),
  };
}
